import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Typography, Box } from "@mui/material";


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Discounts = () => {
  const [urls, setUrls] = useState([]);

  const getPDFs = () => {
    return fetch("https://pas-api-psi.vercel.app/discounts")
      .then((res) => res.json())
      .then((files) => {
        return files.map((file) => {
          return file.id;
        });
      })
      .then((ids) => {
        return Promise.all(
          ids.map((i) => {
            return fetch("https://pas-api-psi.vercel.app/discounts/" + i)
              .then((res) => res.text())
              .then((b64Data) => {
                return fetch("data:application/pdf;base64," + b64Data).then(
                  (res) => res.blob()
                );
              });
          })
        );
      });
  };

  useEffect(() => {
    getPDFs().then((res) => setUrls(res));
  }, []);

  const onDocumentLoadSuccess = () => {
    console.log("it loaded");
  };

  return (
    <Box
      id="discounts"
      sx={{
        color: "primary.main",
        textAlign: "center",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        padding: "1rem",
        gap: "1rem",
      }}
    >
      {/* <Typography variant="h6">Check out our ongoing promotions.</Typography> */}
      {urls.length !== 0 ? (
        <div
          id="carouselExampleControls"
          className="carousel slide"
          data-ride="carousel"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="carousel-inner">
            {urls.map((url, idx) => {
              return (
                <div className={"carousel-item " + (idx === 0 ? "active" : "")}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Document
                      file={url}
                      onDocumentLoadSuccess={onDocumentLoadSuccess}
                    >
                      <Page
                        pageNumber={1}
                        renderTextLayer={false}
                        renderAnnotationLayer={false}
                        height={"500"}
                       />
                    </Document>
                  </div>
                </div>
              );
            })}
          </div>
          <a
            className="carousel-control-prev"
            href="#carouselExampleControls"
            role="button"
            data-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Previous</span>
          </a>
          <a
            className="carousel-control-next"
            href="#carouselExampleControls"
            role="button"
            data-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Next</span>
          </a>
        </div>
      ) : (
        <Typography variant="caption">
          No promotions found at this time.
        </Typography>
      )}
    </Box>
  );
};

export default Discounts;
