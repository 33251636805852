import {
  Box,
  Button,
  Grid,
  Typography,
  TextField,
  Rating,
  MenuItem,
} from "@mui/material";
import StarBorderPurple500Icon from "@mui/icons-material/StarBorderPurple500";

import React, { useState, useEffect } from "react";
import ReviewTile from "./ReviewTile";


const inputProps = {
  input: { color: "white" },
  label: { color: "white" },
  textarea: { color: "white" },
  "& label.Mui-focused": {
    color: "secondary.main",
  },
};

const servicesArray = [
  {
    service: "Dasher Express",
  },
  {
    service: "Blazer Special",
  },
  {
    service: "Tate's Big Boy Special",
  },
  {
    service: "Parker Boss Special",
  },
];

const Ratings = () => {
  const [rating, setRating] = useState(null);
  const [service, setService] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [success, setSuccess] = useState(false);
  const [reviews, setReviews] = useState([]);

  const handleName = (e) => {
    setName(e.target.value);
  };

  const handleService = (e) => {
    setService(e.target.value);
  };

  const handleDescription = (e) => {
    setDescription(e.target.value);
  };

  useEffect(() => {
    getReviews();
  });

  const isComplete = () => {
    return rating && service && name && description;
  }
  const getReviews = () => {
    fetch("https://pas-api-psi.vercel.app/get_reviews")
      .then((res) => res.json())
      .then((docs) => {
        setReviews(docs);
      })
      .catch(() => console.log("Something went wrong"));
  };
  const reset = () => {
    setRating(null);
    setService('');
    setName('');
    setDescription('');
    setSuccess(false)
  }
  const saveRating = async () => {
    return await fetch("https://pas-api-psi.vercel.app/save_review", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        rating: rating,
        name: name,
        service: service,
        description: description,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        getReviews();
        reset();
        setSuccess(true)
      })
      .catch(() => {
        setSuccess(false);
        console.log("something went wrong");
      });

  };
  return (
    <Box
      sx={{
        padding: "1rem",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <Grid
        container
        alignItems="center"
        gap="1rem"
        flexDirection={"column"}
        justifyContent={"flex-start"}
        sx={{
          backgroundColor: "primary.main",
          padding: "1rem",
          borderRadius: "1rem",
        }}
      >
        <Grid item sx={{ padding: "1rem", textAlign: "center" }}>
          <Typography variant="h5" color="secondary">
            We'd love to hear from you!
          </Typography>
        </Grid>
        <Grid item sx={{ width: "100%", display: 'flex', alignItems: "center", justifyContent: "center" }}>
          {reviews.length > 0 ?
            <div
              id="carouselExampleControls"
              className="carousel slide"
              data-ride="carousel"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "90%"
              }}
            >
              <div className="carousel-inner" style={{ maxWidth: "500px" }}>
                {reviews.map((review, idx) => {
                  return <div key={review._id} className={"carousel-item " + (idx === 0 ? "active" : '')}>
                    <ReviewTile review={review} />
                  </div>
                })}
              </div>
              <a
                className="carousel-control-prev"
                href="#carouselExampleControls"
                role="button"
                data-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#carouselExampleControls"
                role="button"
                data-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Next</span>
              </a>
            </div> : <Typography variant="h6" color="white">Be the first to leave a review.</Typography>}
        </Grid>
        <Grid container sx={{ display: "grid" }}>
          <Grid item>
            <Typography variant="body1" color="secondary">
              Leave a Review
            </Typography>
            <Grid item color="primary" sx={{}}>
              <Rating
                componenet="legend"
                value={rating}
                onChange={(e) => {
                  setRating(Number(e.target.value));
                }}
                emptyIcon={
                  <StarBorderPurple500Icon
                    fontSize="inherit"
                    sx={{ color: "primary.dark" }}
                  />
                }
                sx={{
                  "& .MuiRating-iconFilled": {
                    color: "secondary.main",
                  },
                  "& .MuiRating-iconFocus": {
                    color: "secondary.light",
                  },
                }}
              >
                Rating
              </Rating>
            </Grid>
          </Grid>
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "1rem",
            }}
          >
            <TextField
              id="name"
              variant="filled"
              label="Name"
              onChange={handleName}
              value={name}
              fullWidth
              sx={inputProps}
            ></TextField>
            <TextField
              select
              id="service"
              variant="filled"
              value={service}
              label="Service"
              fullWidth
              onChange={handleService}
              sx={inputProps}
              SelectProps={{
                sx: {
                  color: "white",
                  textAlign: "left",
                  "& .MuiSvgIcon-root": {
                    color: "secondary.main",
                  },
                },
              }}
            >
              {servicesArray.map((service, idx) => {
                return (
                  <MenuItem
                    key={idx}
                    value={service}
                    onChange={handleService}
                  >
                    {service.service}
                  </MenuItem>
                );
              })}
            </TextField>
            <TextField
              id="description"
              variant={"filled"}
              label="Message"
              value={description}
              sx={inputProps}
              onChange={handleDescription}
              fullWidth
              multiline
              rows={6}
            ></TextField>
            {success && <Typography variant="h6" color="white">We thank you for the feedback!</Typography>}
            <Button
              variant={isComplete ? "contained" : "disabled"}
              color="secondary"
              fullWidth
              onClick={saveRating}
              disabled={!(rating && service && name && description)}
            >
              Submit
            </Button>
          </form>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Ratings;
