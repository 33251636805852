import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#006AB2",
    },
    secondary: {
      main: "#F8EA19",
    },
    paper: {
      main: "#d4d4d2"
    }
  },
  typography: {
    fontFamily: "Montserrat",
    button: {
      fontFamily: "Montserrat",
      fontWeight: 400,
      fontSize: "1rem",
    },
  },
});
