import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import ServicesPage from "./components/navigation/pages/ServicesPage";
// import ApparelPage from "./components/navigation/pages/ApparelPage";

function App() {
  return (
    <Router>
      <Routes>
        {/* Render the login component alone if we're on /login */}
        {/* <Route exact path="/apparel" element={<ApparelPage />} /> */}

        {/* Otherwise, render the Landing component */}
        <Route path="/" element={<ServicesPage />} />
      </Routes>
      <div className="app" style={{ maxWidth: "1200px", margin: "0 auto" }}>
      </div>
    </Router>
  );
}

export default App;
