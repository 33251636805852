import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import ServicesAppBar from "../ServicesAppBar";
import { theme } from "../../../themes/ServicesTheme";
import { Divider } from "@mui/material";
import HeroSection from "../../HeroSection";
import Services from "../../Services";
import Ratings from "../../Ratings";
import Brag from "../../Brag";
import Footer from "../../Footer";
import Discounts from "../../Discounts";
import Gallery from "../../Gallery";

const ServicesPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <ServicesAppBar />
      <Divider sx={{ color: "black" }}/>
      <HeroSection />
      <Services />
      <Brag />
      <Discounts />
      <Ratings />
      <Gallery />
      <Footer />
    </ThemeProvider>
  );
};

export default ServicesPage;
