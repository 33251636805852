import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { AiFillCar } from "react-icons/ai";
import { BsTruckFrontFill } from "react-icons/bs";
import React, { useState } from "react";
import ServiceItem from "./ServiceItem";
import { items } from "./ServiceData";
import AddOns from "./AddOns";

const sizeButton = {
  display: "flex",
  flexDirection: "column",
  color: "primary.dark",
  "&:hover": {
    backgroundColor: "secondary.main",
  },
  "&:active": {
    backgroundColor: "secondary.main",
  },
};
const Services = () => {
  const [activeSize, setActiveSize] = useState("sm");
  return (
    <Box
      id="services"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "1rem",
        padding: "1rem",
        color: "black",
        "& .active": {
          backgroundColor: "secondary.main",
          border: "none",
        },
      }}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          textTransform: "uppercase",
        }}
      >
        <Typography variant="h3" align="center">
          Exceptional
        </Typography>
        <Typography
          variant="h2"
          align="center"
          color="primary"
          sx={{ textTransform: "uppercase" }}
        >
          Every
        </Typography>
        <Typography variant="h3" align="center">
          Time.
        </Typography>
      </Container>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
          // margin: "0 auto",
          backgroundColor: "primary.main",
          padding: "1rem",
          borderRadius: "1rem",
        }}
      >
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-end",
            gap: "1rem",
          }}
        >
          <Grid item>
            <Button
              className={activeSize === "sm" ? "active" : ""}
              sx={sizeButton}
              onClick={() => setActiveSize("sm")}
            >
              <AiFillCar size={25} />
              Small
            </Button>
          </Grid>
          <Grid item>
            <Button
              className={activeSize === "med" ? "active" : ""}
              sx={sizeButton}
              onClick={() => setActiveSize("med")}
            >
              <AiFillCar size={30} />
              Medium
            </Button>
          </Grid>
          <Grid item>
            <Button
              className={activeSize === "lg" ? "active" : ""}
              sx={sizeButton}
              onClick={() => setActiveSize("lg")}
            >
              <AiFillCar size={40} />
              Large
            </Button>
          </Grid>
          <Grid item>
            <Button
              className={activeSize === "truck" ? "active" : ""}
              sx={sizeButton}
              onClick={() => setActiveSize("truck")}
            >
              <BsTruckFrontFill size={50} />
              Truck
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          {items.map((item) => {
            return (
              <Grid item key={item.title}>
                <ServiceItem item={item} size={activeSize} />
              </Grid>
            );
          })}
        </Grid>
        <Grid item sx={{ width: "100%" }}>
          <AddOns activeSize={activeSize} />
        </Grid>
        <Button
          href="https://ParkerandSonsAutoDetail.as.me/"
          target="_blank"
          variant="contained"
          color="secondary"
          fullWidth
          sx={{ maxWidth: "600px" }}
        >
          Schedule Service
        </Button>
      </Grid>
    </Box>
  );
};

export default Services;
