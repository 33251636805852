import { Box, Grid, Typography } from "@mui/material";
import ImageGallery from "react-image-gallery";
import "./gallery.css";
import React, { useEffect, useState } from "react";


const Gallery = () => {
  const [images, setImages] = useState([]);

  const getReviews = () => {
    return fetch("https://pas-api-psi.vercel.app/gallery")
      .then((res) => res.json())
      .then((files) => {
        const i = [];
       files.map((file) => {
            i.push({original: file.webContentLink})
            return file; 
        });
        setImages(i)
      })
  };
  useEffect(() => {
    getReviews();
  });

  return (
    <div  id="gallery">
      <Box padding={1}>
        <Grid container alignItems={"center"} justifyContent={"center"}>
          <Grid item>
            {images.length !== 0 ? <ImageGallery lazyLoad  showThumbnails={"false"} items={images} /> : <Typography variant="caption">We're sorry, our gallery is unavailable at this time. Please try again later.</Typography>}
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Gallery;
