import { Box } from '@mui/material'
import React from 'react'
import logo from "../images/parker_logo_transparent.png"
import bubbles from "../images/bubbles.png"

const HeroSection = () => {
  return (
    <Box id="home" sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: "1rem", backgroundImage: `url(${bubbles})`, backgroundPosition: 'center',
    backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
      <Box sx={{maxWidth: "500px", height: "auto"}}>
        <img src={logo} alt="hero_logo" style={{width: "100%", height: "100%", objectFit: "cover"}} />
      </Box>
      </Box>
  )
}

export default HeroSection