import {
  Box,
  Container,
  Typography,
  Divider,
  Button,
  List,
  ListItem,
} from "@mui/material";
import {
  BsFillArrowDownCircleFill,
  BsFillArrowUpCircleFill,
} from "react-icons/bs";
import React, { useState } from "react";

const ServiceItem = ({ item, size }) => {
  const [open, setOpen] = useState(false);

  const toggleDescription = () => {
    setOpen(!open);
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        backgroundColor: "primary.dark",
        padding: "1rem",
        color: "white",
      }}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
        }}
      >
        <Typography variant="h6" color="secondary">
          {item.title}
        </Typography>
        <Button
          variant="outlined"
          size="small"
          endIcon={
            open ? <BsFillArrowUpCircleFill /> : <BsFillArrowDownCircleFill />
          }
          sx={{ color: "white", transition: "all 0.2 ease-in" }}
          onClick={toggleDescription}
        >
          What's Included
        </Button>
        {open && (
          <List>
            {item.included.map((i) => {
              return <ListItem>{i}</ListItem>;
            })}
          </List>
        )}
      </Container>
      <Divider orientation="vertical" />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyConten: "center",
          background: "white",
          height: "100%",
          color: "primary.main",
          padding: "1rem",
          borderRadius: "1rem",
          textAlign: "center",
          width: "50%"
        }}
      >
        <Typography variant="caption" noWrap>Starting at</Typography>
        <Typography variant="h4">
          $
          {(size === "sm" && item.sm) ||
            (size === "med" && item.med) ||
            (size === "lg" && item.lg) ||
            (size === "truck" && item.truck)}
        </Typography>
      </Box>
    </Box>
  );
};

export default ServiceItem;
