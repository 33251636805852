import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { BsFillPlusSquareFill } from "react-icons/bs";

export const data = [
  {
    title: "Butter Wet Wax",
    sm: "45",
    med: "55",
    lg: "65",
    truck: "75 ",
  },
  {
    title: "Mirror Glass Finish",
    sm: "40",
    med: "45",
    lg: "50",
    truck: "60",
  },
  {
    title: "Hydro Thread ",
    caption: "Ceramic Stain Repellent",
    two: "30",
    three: "35",
  },
  {
    title: "Hydro View",
    caption: "Ceramic Window Protection",
    sm: "35",
    med: "35",
    lg: "35",
    truck: "35 ",
  },
  {
    title: "Headlight Restoration",
    sm: "50",
    med: "50",
    lg: "50",
    truck: "50 ",
  },
  {
    title: "Carpet Shampoo",
    starting: "49.99",
  },
];

const AddOns = ({ activeSize }) => {
  const [toggled, setToggled] = useState(false);

  const togglePrice = () => {
    setToggled(!toggled);
  };
  return (
    <Grid
      container
      sx={{
        padding: "1rem",
        gap: "1rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "1rem",
          color: "secondary.main",
          backgroundColor: "primary.dark",
          padding: "1rem",
          borderRadius: "1rem",
        }}
      >
        <BsFillPlusSquareFill size={50} />
        <Typography variant="h5" sx={{ textTransform: "uppercase" }}>
          Add Ons
        </Typography>
      </Grid>
      <Grid
        item
        sx={{ display: "flex", flexDirection: "column", gap: ".5rem" }}
      >
        {data.map((add) => {
          return (
            <Grid
              item
              key={add.title}
              sx={{
                display: "flex",
                flexalignItems: "center",
                justifyContent: "space-between",
                backgroundColor: "primary.dark",
                gap: "1rem",
                padding: "1rem",
                borderRadius: "1rem",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography color="secondary">{add.title}</Typography>
                {add.caption && (
                  <Typography variant="caption" color="white">
                    {add.caption}
                  </Typography>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                {add.starting && (
                  <Typography variant="caption" color="white">
                    Starting at
                  </Typography>
                )}
                {add.two && (
                  <Button
                    variant="outlined"
                    onClick={togglePrice}
                    color="secondary"
                    sx={{ height: "25px" }}
                  >
                    {toggled ? "3" : "2"} Row
                  </Button>
                )}
                <Typography variant="h5" sx={{ color: "white" }}>
                  $
                  {(activeSize === "sm" && add.sm) ||
                    (activeSize === "med" && add.med) ||
                    (activeSize === "lg" && add.lg) ||
                    (activeSize === "truck" && add.truck) ||
                    (toggled ? add.three : add.two) ||
                    (add.starting && add.starting)}
                </Typography>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default AddOns;
