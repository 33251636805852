export const items = [
  {
    title: "Dasher Express",
    sm: "45",
    med: "50",
    lg: "55",
    truck: "65 ",
    included: ["Wash", "Vacuum", "Light interior cleaning"]
  },
  {
    title: "BLAZER SPECIAL",
    sm: "100",
    med: "110",
    lg: "120",
    truck: "145",
    included: ["Full interior and exterior detail"]
  },
  {
    title: "TATE’S “BIG BOY” SPECIAL",
    description: "Interior and exterior detail Wash + Wax",
    sm: "125",
    med: "135",
    lg: "145",
    truck: "165",
    included: ["Interior and exterior detail", "Wash", "Wax"]
  },
  {
    title: "PARKER’S “BOSS” SPECIAL",
    description: "Wash/Vacuum/Light Interior Wipe Down",
    sm: "225",
    med: "235",
    lg: "245",
    truck: "265",
    included: ["Interior and exterior detail", "Wash", "Wax", "Matte cleanse", "Seat/leather cleaning + conditioning", "Ceramic wheel protection coating"]
  },
];
