import { Box, Typography } from "@mui/material";
import React from "react";

const Highlight = ({ info }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "1rem",
        color: "primary.main",
        textAlign: "center",
        height: "100%",
        dropShadow: "20px 20px 5px black",
        // ":hover": {
        //   backgroundColor: "primary.main",
        //   transition: "all 0.2s ease-in",
        // },
      }}
    >
      <Box
        sx={{
          padding: "1rem",
          backgroundColor: "secondary.main",
          borderRadius: "50%",
        }}
      >
        {info.icon}
      </Box>
      <Typography variant="h5" sx={{}}>
        {info.title}
      </Typography>
      <Typography variant="body1" sx={{}}>
        {info.description}
      </Typography>
    </Box>
  );
};

export default Highlight;
