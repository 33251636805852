import { AppBar, Toolbar, Box, Button } from "@mui/material";
import { RiMenu2Line } from "react-icons/ri";
import logo from "../../images/parker_logo_transparent.png";
import React, { useState } from "react";
import ServicesSideBar from "./ServicesSideBar";
import { BsArrowRightShort } from "react-icons/bs";
import { Link as LinkS } from "react-scroll";

import "./NavBar.css";

const linkStyle = {
  fontWeight: "bold",
  padding: ".5rem",
};
const sections = ["home", "services", "discounts", "gallery"];
const ServicesAppBar = () => {
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState("home");

  const toggleSideDrawer = () => {
    setOpen(!open);
  };
  return (
    <>
      <ServicesSideBar
        open={open}
        toggleSideDrawer={toggleSideDrawer}
        active={active}
        setActive={setActive}
      />
      <AppBar
        position="sticky"
        sx={{
          background: "white",
          color: "primary.main",
        }}
      >
        {" "}
        <Toolbar
          sx={{
            display: "flex",
            gap: "1rem",
            padding: "0 1rem",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LinkS
            to="home"
            smooth
            offset={-150}
            style={{ maxWidth: "150px", height: "auto", padding: "1rem", cursor: "pointer" }}
          >
            <img
              src={logo}
              alt="nav_logo"
              style={{ height: "100%", width: "100%" }}
            />
          </LinkS>
          <Box sx={{ display: { xs: "none", md: "flex", gap: ".5rem" } }}>
            {sections.map((section) => (
              <LinkS
                key={section}
                className={active === section ? "activeLink" : section}
                to={section}
                smooth
                offset={-150}
                spy={true}
                style={linkStyle}
                onClick={() => setActive(section)}
              >
                {section.toLocaleUpperCase()}
              </LinkS>
            ))}
          </Box>
          <Box
            sx={{
              display: { xs: "flex", md: "none" },
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={toggleSideDrawer}
          >
            <RiMenu2Line />
            <Button>Menu</Button>
          </Box>
          <Button href="https://ParkerandSonsAutoDetail.as.me/" target="_blank" variant="contained" sx={{ display: {xs: "none", md: "flex"}}}>Schedule</Button>
        </Toolbar>
        <Button
          href="https://free-shop-105475.square.site"
          target="_blank"
          variant="contained"
          color="secondary"
          sx={{
            backgroundColor: "secondary.main",
            color: "primary.main",
            position: "fixed",
            bottom: "0px",
          }}
        >
          Shop Apparel
          <BsArrowRightShort size={30} />
        </Button>
      </AppBar>
    </>
  );
};

export default ServicesAppBar;
