import { Button, Drawer, Grid } from "@mui/material";
import React from "react";
import logo from "../../images/parker_logo_transparent.png";
import { AiOutlineCloseCircle, AiFillInstagram, AiFillPhone } from "react-icons/ai";
import { MdEmail } from "react-icons/md";
import { Link } from "react-scroll"; 


const iconStyle = {
    fontSize: "25px",


  }

const linkStyle = {
  textTransform: "uppercase", 
  fontWeight: "bold",
  width: "100%", 
  border: "1px solid #000",
  padding: ".2rem",
  textAlign: "center",
  color: "#0070B7"

}
const sections = ["home", "services", "discounts", "gallery"];

const ServicesSideBar = ({ open, toggleSideDrawer, active, setActive }) => {
  return (
    <Drawer open={open} onClose={toggleSideDrawer} sx={{}}>
      <Grid
        container
        color="primary"
        sx={{
          display: "grid",
          padding: "1rem",
          alignItems: "center",
          justifyContent: "space-between",
          height: "100%",
          gap: "1rem"
        }}
      >
        <Grid item sx={{ color: "primary.main" }} onClick={toggleSideDrawer}>
          <AiOutlineCloseCircle size={35} style={{ color: "primary.main" }} />
        </Grid>
        <Grid item sx={{ maxWidth: "250px", height: "auto" }}>
          <img
            src={logo}
            alt="sidebar_logo"
            style={{ width: "100%", height: "100%" }}
          />
        </Grid>
        <Grid item sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'primary.dark'}}>
          <Button href="https://www.instagram.com/parkersandsons_autodetailing/" target="_blank"><AiFillInstagram style={iconStyle} /></Button>
          <Button href="mailto:parkerandsonsautodetail@gmail.com"><MdEmail style={iconStyle} /></Button>
          <Button href="tel:4787039202"><AiFillPhone style={iconStyle} /></Button>
        </Grid>
        <Grid item sx={{ display: "grid", gap: ".5rem" }}>
          {sections.map((section) => {
            return <Link key={section} to={section} smooth offset={-150} onClick={() => {
              setActive(section); 
              toggleSideDrawer(); 
            }} style={linkStyle}>{section.toLocaleUpperCase()}</Link>;
          })}
        </Grid>
        <Grid item sx={{ display: "grid", gap: ".5rem" }}>
          <Button href="https://ParkerandSonsAutoDetail.as.me/" target="_blank" variant="contained" color="primary">
            Schedule Service
          </Button>
          <Button  href="https://free-shop-105475.square.site" variant="contained" color="secondary">
            Shop Apparel
          </Button>
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default ServicesSideBar;
