import { Divider, Grid } from "@mui/material";
import Highlight from "./Highlight";
import React from "react";
import book from "../images/booking.svg";
import mobile from "../images/mobile.svg";
import payment from "../images/payment.svg";
import { GiSteeringWheel } from "react-icons/gi"
import { BsFillCreditCard2BackFill } from "react-icons/bs"
import { AiFillSchedule } from "react-icons/ai"

const iconStyle = {
  fontSize: "40px", 
  color: "primary.main"
}

const data = [
  {
    title: "Online Booking",
    description: "Book quality service with just a few clicks.",
    img: book,
    icon: <AiFillSchedule style={iconStyle}/>
  },
  {
    title: "Mobile Service",
    description: "We'll come to you!",
    img: mobile,
    icon: <GiSteeringWheel style={iconStyle}/>
  },
  {
    title: "Secure Payment",
    description: "Protected card transactions.",
    img: payment,
    icon: <BsFillCreditCard2BackFill style={iconStyle} />
  },
];

const Brag = () => {
  return (
    <Grid
      container
      sx={{
        display: "grid",
        gridTemplateRows: {xs: "repeat(3, 1fr)", md: "none"},
        gridTemplateColumns: {md: "repeat(3, 1fr)"},
        gap: "2rem",
        padding: "1rem",

      }}
    >
      {data.map((highlight) => {
        return (
          <Grid key={highlight.title} item sx={{ borderRadius: "1rem"}}>
            <Highlight info={highlight} style={{ height: "100%" }} />
            <Divider />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default Brag;
