import { Box, Grid, Rating, Typography } from "@mui/material";
import StarBorderPurple500Icon from "@mui/icons-material/StarBorderPurple500";
import React from "react";

const ReviewTile = ({ review }) => {


  return (
    <Box
      sx={{
        padding: "1rem",
        backgroundColor: "primary.dark",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        borderRadius: "1rem",
      }}
    >
      <Grid container justifyContent={"space-between"} sx={{ padding: "1rem" }}>
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Typography
            variant="h5"
            color="white"
            sx={{
              backgroundColor: "primary.main",
              padding: "1rem",
              borderRadius: "1rem",
            }}
          >
            {review.name}
          </Typography>
          <Typography variant="h6" color="white">
            {review.service}
          </Typography>
          <Rating
            componenet="legend"
            value={review.rating}
            readOnly
            emptyIcon={
              <StarBorderPurple500Icon
                fontSize="inherit"
                sx={{ color: "primary.light" }}
              />
            }
            sx={{
              "& .MuiRating-iconFilled": {
                color: "secondary.main",
              },
            }}
          >
            Rating
          </Rating>
        </Grid>

      </Grid>
      <Typography color="white" sx={{ padding: "1rem" }}>
        {review.description}
      </Typography>
    </Box>
  );
};

export default ReviewTile;
