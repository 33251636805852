import React from 'react'
import { Box, Grid, Typography, Button } from '@mui/material'
import logo from '../images/parker_logo-1.png'
import {AiFillInstagram, AiFillPhone} from 'react-icons/ai'
import {MdEmail} from 'react-icons/md'


const iconStyle = {
  fontSize: "25px",
  color: "primary.main"
}
const Footer = () => {
  return (
    <Box>
      <Grid container sx={{display: "flex", flexDirection: "column", gap: "1rem", alignItems: "center", justifyContent: "center", padding: "1rem", color: "primary.main"}}>
        <Grid item sx={{ display: "flex", gap: "1rem"}}>
        <Button href="https://www.instagram.com/parkersandsons_autodetailing/" target="_blank"><AiFillInstagram style={iconStyle} /></Button>

          <Button href="mailto:parkerandsonsautodetail@gmail.com"><MdEmail style={iconStyle} /></Button>
          <Button href="tel:4787039202"><AiFillPhone style={iconStyle} /></Button>
        </Grid>
        <Grid item sx={{width: "150px"}}>
          <img src={logo} alt="footer_logo" style={{ height: "100%", width: "100%" }}/>
        </Grid>
        <Grid item>
          <Typography variant="caption"> © 2023 All rights reserved. Created by <a href='https://www.themocreate.com/' target="_blank" rel="noreferrer">MOCREATE, LLC. </a> with ♥.</Typography>
        </Grid>
      </Grid>
     
    </Box>
    
  )
}

export default Footer